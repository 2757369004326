/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
import moment from 'moment';
import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  Api,
  ConfirmDialog,
  downloadFilesFromUrlToZip,
  exportToExcel,
  FileUploaderModal,
  getDisabledStatusForTransactionSlip,
  GetPaymentTransactionsRequestDto,
  getPaymentTransactionStatusEnumForLookup,
  getPaymentTransactionStatusEnumString,
  PaginationQuery,
  PaymentSourceEnum,
  PaymentSourceEnumLabel,
  PaymentTransaction,
  PaymentTransactionFileTypeEnum,
  PaymentTransactionStatusEnum,
  showToast,
  SortingQuery,
  TranslatedMaterialTable,
} from 'core';
import { Filter } from 'material-table';
import { Box, Button, Chip, Grid, IconButton, Menu, MenuItem, MenuList, TextField } from '@material-ui/core';
import { ApproveConsentFileDialog, PaynetReverseTransactionRequest, SendConsentFileDialog } from 'apps/paynet';
import { UserInfo } from 'apps/auth/models';
import { useHistory } from 'react-router';
import { ArrowBack, Cancel, CloudDownload, CloudUpload, Description, FontDownload, Link } from '@material-ui/icons';

const PaynetPaymentListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [selectedPayment, setSelectedPayment] = React.useState<PaymentTransaction | null>(null);
  const [statusFilter, setStatusFilter] = React.useState<PaymentTransactionStatusEnum[]>([]);
  const [showReverseTransactionDialog, setShowReverseTransactionDialog] = React.useState(false);
  const [openUploaderModal, setOpenUploaderModal] = React.useState<boolean>(false);
  const [reLoadTable, setReLoadTable] = React.useState(false);
  const [columns, setColumns] = React.useState<any[]>();
  const [refundAmount, setRefundAmount] = React.useState<number>(0);
  const [partialRefundAvailable, setPartialRefundAvailable] = React.useState<boolean>(true);
  const [showSendConsentFileDialog, setShowSendConsentFileDialog] = React.useState<boolean>(false);
  const [showApproveConsentFileDialog, setShowApproveConsentFileDialog] = React.useState<boolean>(false);
  const [showTransactionFilesDialog, setShowTransactionFilesDialog] = React.useState(false);
  const [showConsentFileDialog, setShowConsentFileDialog] = React.useState<boolean>(false);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [focusedData, setFocusedData] = React.useState<any>([]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSettings(true);
  };
  const getStatusChipColor = (status: any) => {
    switch (status) {
      case 0:
        return '#FF5733'; // PRE_AUTH_OR_WAITING_PAYMENT - Kırmızı
      case 1:
        return '#33FF57'; // COMPLETED - Yeşil
      case 2:
        return '#FFC300'; // TIMEOUT - Sarı
      case 3:
        return '#FF0000'; // FAILED - Kırmızı
      case 4:
        return '#DAF7A6'; // EMPTY - Açık Yeşil
      case 5:
        return '#33FFBD'; // CANCELLED_OR_RETURNED - Turkuaz
      case 6:
        return '#3385FF'; // WAITING_APPROVAL_CANCEL_OR_RETURN - Mavi
      case 7:
        return '#8A33FF'; // REJECTED_CANCEL_OR_RETURN - Mor
      case 8:
        return '#FF8D33'; // PARTIAL_RETURNED_OR_CANCELED - Turuncu
      case 20:
        return '#FFC300'; // PARTIAL_RETURNED_OR_CANCELED - Sarı
      case 30:
        return '#3385FF'; // PARTIAL_RETURNED_OR_CANCELED - Mavi
      default:
        return '#000000'; // Varsayılan renk - Siyah
    }
  };

  const { userInfo } = useSelector(
    (state: any) => ({
      userInfo: state.auth.userInfo as UserInfo,
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const actions: any[] = [
    (rowData: PaymentTransaction) => ({
      icon: (props: any) => (
        <Button
          variant="contained"
          style={{ background: 'orange' }}
          // eslint-disable-next-line react/destructuring-assignment
        >
          Seçenekler
        </Button>
      ),
      onClick: (event: any, rowData: any) => {
        setFocusedData(rowData);
        handleProfileMenuOpen(event);
      },
    }),
    /*     {
      icon: 'upload',
      tooltip: 'Dosya Yükle',
      onClick: (event: any, rowData: PaymentTransaction) => {
        setSelectedPayment(rowData);
        setOpenUploaderModal(true);
      },
    },
    (rowData: PaymentTransaction) => ({
      icon: 'download',
      tooltip: 'Dosyaları İndir',
      disabled: !rowData.files || rowData.files.length === 0,
      onClick: (event: any, data: any) => {
        downloadFiles(data);
      },
    }),
    (rowData: PaymentTransaction) => ({
      icon: 'cancel',
      tooltip: 'Ödemeyi İptal / İade Et',
      disabled: rowData.isRefundable === false,
      onClick: (event: any, data: PaymentTransaction) => {
        setPartialRefundAvailable(
          data.transactionDate !== undefined &&
            new Date(data.transactionDate).toDateString() !== new Date().toDateString(),
        );
        setSelectedPayment(data);
        setRefundAmount(data.refundableAmount);
        setShowReverseTransactionDialog(true);
      },
    }),
    (rowData: PaymentTransaction) => ({
      icon: 'description',
      tooltip: 'Slip Bilgisini Gör',
      disabled: getDisabledStatusForTransactionSlip(rowData.status),
      onClick: (event: any, data: PaymentTransaction) => {
        setSelectedPayment(data);
        getPaymentTransactionSlip(data);
      },
    }),
    (rowData: PaymentTransaction) => ({
      icon: 'link',
      tooltip: 'Muvafakatname Sözleşmesi Gönder',
      disabled:
        !userInfo.isAdmin &&
        (rowData.files?.find((x) => x.fileType === PaymentTransactionFileTypeEnum.CONSENT) ||
          rowData.status === PaymentTransactionStatusEnum.COMPLETED),
      onClick: (event: any, data: PaymentTransaction) => {
        setSelectedPayment(data);
        onClickConsentFileButton(data);
      },
    }), */
  ];

  const getColumns = (): any[] => {
    return [
      {
        title: 'Durum',
        render: (value: any) => (
          <Chip
            // eslint-disable-next-line react/destructuring-assignment
            label={getPaymentTransactionStatusEnumString(value.status as PaymentTransactionStatusEnum)}
            style={{ background: getStatusChipColor(value.status) }}
          />
        ),
        field: 'status',
        type: 'string',
        cellStyle: { whiteSpace: 'nowrap' },
        sorting: true,
        lookup: getPaymentTransactionStatusEnumForLookup(),
        defaultFilter: statusFilter.map((x) => x.toString()),
        filterPlaceholder: 'Seçiniz',
        searching: false,
      },
      {
        title: 'Sağlayıcı',
        cellStyle: { whiteSpace: 'nowrap' },
        render: (value: any) => PaymentSourceEnumLabel.get(value.providerType),
        field: 'providerType',
        sorting: true,
        filtering: false,
        searching: false,
      },
      {
        title: 'Müşteri Adı',
        field: 'cardHolder',
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Taksit Sayısı',
        field: 'instalment',
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Brüt Tutar',
        field: 'grossAmount',
        render: (value: any) => value.grossAmount?.toFixed(2),
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Net Tutar',
        field: 'netAmount',
        render: (value: any) => value.netAmount?.toFixed(2),
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },

      {
        title: 'İşlem Tarihi',
        type: 'date',
        render: (value: any) => moment(value.transactionDate).format('DD/MM/YYYY HH:mm'),
        field: 'transactionDate',
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
    ];
  };

  const handleDialogClose = () => {
    setShowReverseTransactionDialog(false);
    setShowTransactionFilesDialog(false);
  };

  const onClickConsentFileButton = async (paymentTransaction: PaymentTransaction) => {
    const hasWaitingFileResponse = await Api.hasWaitingConsentApproval(paymentTransaction.id);
    if (!hasWaitingFileResponse.isSuccess) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${hasWaitingFileResponse.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
      return;
    }

    if (!hasWaitingFileResponse.result) {
      setShowSendConsentFileDialog(true);
    } else {
      setShowConsentFileDialog(true);
    }
  };

  const uploadFiles = async (files: any[]) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });

    formData.append('mailOrderId', selectedPayment?.mailOrderId?.toString() ?? '');
    formData.append('paymentTransactionId', selectedPayment?.id.toString() ?? '');

    setShowLoading(true);

    const response = await Api.uploadFiles(formData);
    if (response.isSuccess) {
      setReLoadTable(true);
      dispatch(
        showToast({
          message: `Dosyalar başarıyla yüklendi.`,
        }),
      );
    } else {
      dispatch(
        showToast({
          message: `Dosyalar yüklenirken bir hata oluştu. ${response.errorMessage}`,
          severity: 'error',
        }),
      );
    }
    setShowLoading(false);
    setReLoadTable(false);
  };

  const downloadFiles = async (paymentTransaction: any) => {
    if (!paymentTransaction.files || paymentTransaction.files.length == 0) {
      dispatch(
        showToast({
          message: `Bu işlem için hiç dosya yüklenmemiş.`,
          severity: 'warning',
        }),
      );
      return;
    }

    const urls = paymentTransaction.files.map((file: any) => file.url as string);
    for (const url of urls) {
      window.open(url, '_blank');
    }

    // const zipFileName = paymentTransaction.cardHolder.replaceAll(' ', '_');

    // const response = await downloadFilesFromUrlToZip(urls, zipFileName);
    // if (response && response.isSuccess) {
    //   dispatch(
    //     showToast({
    //       message: `Dosyalar başarıyla indirildi.`,
    //     }),
    //   );
    // } else {
    //   dispatch(
    //     showToast({
    //       message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
    //       severity: 'error',
    //     }),
    //   );
    // }
  };

  const getSortingQuery = (orderBy: any, orderDirection: 'asc' | 'desc') => {
    if (orderBy == undefined || orderBy == null) {
      return null;
    }

    const sorting = {
      orderBy: orderBy.field as string,
      order: orderDirection.toUpperCase(),
    } as SortingQuery;

    return sorting;
  };

  const getPaymentTransactions = async (query: any) => {
    const { filters, orderBy, orderDirection, page, pageSize, search } = query;
    setShowLoading(true);
    try {
      const statusFiltered = filters
        .find((x: Filter<any>) => x.column.field == 'status')
        ?.value.map((val: string) => Number(val) as PaymentTransactionStatusEnum);
      setStatusFilter(statusFiltered);

      const requestDto = {
        paginationQuery: { page, pageSize } as PaginationQuery,
        providerType: PaymentSourceEnum.PAYNET,
        sortingQuery: getSortingQuery(orderBy, orderDirection),
        searchText: search,
        statuses: statusFiltered,
      } as GetPaymentTransactionsRequestDto;

      const response = await Api.getPaymentTransactions(requestDto);
      if (response.isSuccess) {
        const { result } = response;
        return {
          data: result?.data ?? [],
          page,
          totalCount: result?.totalCount ?? 0,
        };
      }
      dispatch(
        showToast({
          message: `Ödeme listesi çekilirken bir hata oluştu. ${response.errorMessage}`,
          severity: 'error',
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Ödeme listesi çekilirken bir hata oluştu. ${e.message}`,
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }
    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  };

  const getAllPaymentTransactions = async () => {
    setShowLoading(true);
    try {
      const requestDto = {
        providerType: PaymentSourceEnum.PAYNET,
      } as GetPaymentTransactionsRequestDto;

      const response = await Api.getTransactionsWithoutPagination(requestDto);
      if (response.isSuccess) {
        return response.result;
      }
      dispatch(
        showToast({
          message: `Ödeme listesi çekilirken bir hata oluştu. ${response.errorMessage}`,
          severity: 'error',
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Ödeme listesi çekilirken bir hata oluştu. ${e.message}`,
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }
    return null;
  };

  const onSaveFiles = async (files: any) => {
    await uploadFiles(files);
  };

  const onBlurRefundAmount = (event: any) => {
    const amount = event.target.value;
    setRefundAmount(Number(amount));
  };

  const validateRefundAmount = (): boolean => {
    if (!selectedPayment) {
      return false;
    }

    // kısmi iade yapılamıyorsa, true olarak dön. Çünkü tüm tutar iade olacak
    if (!partialRefundAvailable) {
      return true;
    }

    let message = null;

    if (refundAmount < 1) {
      message = `İade/iptal edeceğiniz tutar 1 TL'den büyük olmalıdır.`;
    } else if (refundAmount > selectedPayment.refundableAmount) {
      message = `İade/iptal edebileceğiniz maksimum tutar: ${selectedPayment.refundableAmount} TL`;
    } else if (
      selectedPayment.refundableAmount - refundAmount <= 1 &&
      selectedPayment.refundableAmount - refundAmount !== 0
    ) {
      message = `Lütfen maksimum iade/iptal tutarını giriniz.
                  (Kalan iade/iptal tutarı 1 TL'den küçük olamaz)`;
    }

    if (message) {
      dispatch(
        showToast({
          message,
          severity: 'error',
        }),
      );
      return false;
    }

    return true;
  };

  const reverseTransaction = async () => {
    if (selectedPayment) {
      if (!validateRefundAmount()) {
        return;
      }

      handleDialogClose();
      setShowLoading(true);

      const request = {
        paymentTransactionId: Number(selectedPayment.id),
        xactId: selectedPayment.transactionIdHash?.toString(),
        amount: refundAmount,
      } as PaynetReverseTransactionRequest;

      const response = await Api.Paynet.reverseTransaction(request, selectedPayment.providerType);
      if (response.isSuccess) {
        setReLoadTable(true);
        dispatch(
          showToast({
            message: `İptal/İade talebi başarıyla oluşturuldu!`,
          }),
        );
      } else {
        dispatch(
          showToast({
            message: `${response.errorMessage ?? 'Lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    }
    setShowReverseTransactionDialog(false);
    setShowLoading(false);
    setSelectedPayment(null);
    setReLoadTable(false);
  };

  const getPaymentTransactionSlip = async (paymentTransaction: PaymentTransaction) => {
    if (paymentTransaction) {
      setShowLoading(true);

      const response = await Api.getPaymentTransactionSlip(paymentTransaction.id);
      if (response.isSuccess) {
        const newWindow = window.open(response.result?.url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      } else {
        dispatch(
          showToast({
            message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    }
    setShowLoading(false);
    setSelectedPayment(null);
  };

  const exportToExcelAllData = async (columns: any) => {
    const allPaymentTransactions = await getAllPaymentTransactions();
    if (!allPaymentTransactions) return;

    exportToExcel(columns, allPaymentTransactions);
  };

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        open={openSettings}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setOpenSettings(false)}
      >
        <MenuList>
          <MenuItem
            onClick={() => {
              setSelectedPayment(focusedData);
              setOpenUploaderModal(true);
            }}
          >
            <CloudUpload />
            <span style={{ marginLeft: 15 }}>Noter Satış Belgesi/Konsinye Sözleşmesi</span>
          </MenuItem>
          <br />
          <MenuItem
            disabled={!focusedData.files || focusedData.files.length === 0}
            onClick={() => {
              setSelectedPayment(focusedData);
              setShowTransactionFilesDialog(true);
            }}
          >
            <CloudDownload />
            <span style={{ marginLeft: 15 }}> Dosyaları Görüntüle</span>
          </MenuItem>

          <br />
          <MenuItem
            disabled={focusedData.isRefundable === false}
            onClick={() => {
              setPartialRefundAvailable(
                focusedData.transactionDate !== undefined &&
                  new Date(focusedData.transactionDate).toDateString() !== new Date().toDateString(),
              );
              setSelectedPayment(focusedData);
              setRefundAmount(focusedData.refundableAmount);
              setShowReverseTransactionDialog(true);
            }}
          >
            <Cancel /> <span style={{ marginLeft: 15 }}>Ödemeyi İptal / İade Et</span>
          </MenuItem>
          <br />
          <MenuItem
            hidden={focusedData.providerType != PaymentSourceEnum.PAYNET}
            disabled={getDisabledStatusForTransactionSlip(focusedData.status)}
            onClick={() => {
              setSelectedPayment(focusedData);
              getPaymentTransactionSlip(focusedData);
            }}
          >
            <Description />
            <span style={{ marginLeft: 15 }}>Slip Bilgisini Gör</span>
          </MenuItem>
          <br />
          <MenuItem
            disabled={
              !userInfo.isAdmin &&
              (focusedData.files?.find((x: any) => x.fileType === PaymentTransactionFileTypeEnum.CONSENT) ||
                focusedData.status === PaymentTransactionStatusEnum.COMPLETED)
            }
            onClick={() => {
              setSelectedPayment(focusedData);
              onClickConsentFileButton(focusedData);
            }}
          >
            <Link />
            <span style={{ marginLeft: 15 }}>Muvafakatname Sözleşmesi Gönder</span>
          </MenuItem>
        </MenuList>
      </Menu>
      <br />
      <br />
      <IconButton onClick={() => history.goBack()}>
        <ArrowBack />
      </IconButton>
      {!reLoadTable && (
        <TranslatedMaterialTable
          title="Ödeme Listesi"
          columns={columns ?? getColumns()}
          data={getPaymentTransactions}
          actions={actions}
          isLoading={showLoading}
          options={{
            filtering: true,
            pageSize: 100,
            exportAllData: true,
            exportButton: { csv: true, pdf: false },
            exportCsv: exportToExcelAllData,
          }}
        />
      )}
      <FileUploaderModal
        open={openUploaderModal}
        onSave={onSaveFiles}
        onClose={() => {
          setOpenUploaderModal(false);
          setSelectedPayment(null);
        }}
      />
      <ConfirmDialog
        title="Ödeme İptal/İade"
        open={showReverseTransactionDialog}
        // eslint-disable-next-line no-lone-blocks
        handleConfirm={reverseTransaction}
        handleClose={handleDialogClose}
      >
        {selectedPayment && partialRefundAvailable && (
          <div>
            <b>{selectedPayment?.transactionId}</b> numaralı işlem için iptal/iade etmek istediğiniz tutarı giriniz.{' '}
            <b>(Maks. {selectedPayment.refundableAmount} TL</b>)
            <br />
          </div>
        )}

        {selectedPayment && !partialRefundAvailable && (
          <div>
            <b>{selectedPayment?.transactionId}</b> numaralı işlemin tüm tutarı iade/iptal olacak. Ödemenin alındığı gün
            kısmi iade yapılamaz ve bütün tutar iade/iptal olur.
            <br />
          </div>
        )}

        {selectedPayment && (
          <TextField
            autoFocus
            margin="dense"
            id="refundableAmount"
            label="Tutar"
            type="number"
            disabled={!partialRefundAvailable}
            defaultValue={refundAmount}
            onBlur={onBlurRefundAmount}
          />
        )}
      </ConfirmDialog>
      <SendConsentFileDialog
        open={showSendConsentFileDialog}
        paymentTransactionId={selectedPayment?.id ?? 0}
        handleClose={() => {
          setShowSendConsentFileDialog(false);
          setShowConsentFileDialog(false);
        }}
      />
      <ApproveConsentFileDialog
        paymentTransactionId={selectedPayment?.id ?? 0}
        open={showApproveConsentFileDialog}
        handleClose={() => {
          setShowApproveConsentFileDialog(false);
          setShowConsentFileDialog(false);
        }}
      />
      <ConfirmDialog
        title="Muvafakatname Zaten Gönderilmiş"
        cancelText="Tekrar Gönder"
        confirmText="Muvafakatnameyi Onayla"
        open={showConsentFileDialog}
        handleClose={() => setShowSendConsentFileDialog(true)}
        handleConfirm={() => setShowApproveConsentFileDialog(true)}
      >
        <div>
          <span>
            Bu işlem için Muvafakatname linki kart sahibine zaten gönderilmiş. Dilerseniz bilgileri girip tekrar
            gönderebilir veya gönderilen Muvafakatnameyi onaylayabilirsiniz.
          </span>
        </div>
      </ConfirmDialog>
      <ConfirmDialog
        title="Yüklenen Dosyalar"
        confirmText=""
        cancelText="Kapat"
        open={showTransactionFilesDialog}
        // eslint-disable-next-line no-lone-blocks
        handleClose={handleDialogClose}
        handleConfirm={handleDialogClose}
      >
        {selectedPayment && (
          <Box>
            <Grid container spacing={2}>
              {selectedPayment.files
                .filter((x) => x.url.endsWith('pdf'))
                .map((item) => (
                  <Grid key={`img-${item.id}`} item xs={6} sm={6} md={6}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img src="/pdf1.png" alt="" style={{ width: '%100', height: 200 }} />
                    </a>
{/* 
                    <div style={{ width: '50%' }}>
                      <p>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          Görüntüle
                        </a>
                      </p>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <img src="/pdf.png" alt="" style={{ width: '%100', height: 200 }} />
                      </a>
                      <object
                        data={item.url}
                        type="application/pdf"
                        width="80%"
                        height="100%"
                        style={{ marginTop: '100' }}
                      />
                    </div> */}
                  </Grid>
                ))}
              {selectedPayment.files
                .filter((x) => !x.url.endsWith('pdf'))
                .map((item) => (
                  <Grid key={`img-${item.id}`} item xs={6} sm={6} md={6}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img src={item.url} alt="" style={{ width: '%100', height: 250 }} />
                    </a>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default PaynetPaymentListPage;
