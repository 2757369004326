/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import React from 'react';
import {
  Api,
  ConfirmDialog,
  DateRangeSelectRow,
  downloadBlobAsFile,
  exportToExcel,
  getDisabledStatusForTransactionSlip,
  GetPaymentTransactionsRequestDto,
  getPaymentTransactionStatusEnumForLookup,
  getPaymentTransactionStatusEnumString,
  getSortingQuery,
  PaginationQuery,
  PaymentSourceEnum,
  PaymentSourceEnumLabel,
  PaymentTransaction,
  PaymentTransactionStatusEnum,
  showToast,
  TranslatedMaterialTable,
} from 'core';
import { PaynetCaptureTransactionRequest, PaynetReverseTransactionRequest } from 'apps/paynet';
import { useDispatch } from 'react-redux';
import { Filter } from 'material-table';
import { Box, Button, Chip, Grid, IconButton, Menu, MenuItem, MenuList, TextField } from '@material-ui/core';
import { ArrowBack, Cancel, Check, CloudDownload, Description } from '@material-ui/icons';
import { useHistory } from 'react-router';

const PaymentListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [statusFilter, setStatusFilter] = React.useState<PaymentTransactionStatusEnum[]>([]);
  const [selectedPayment, setSelectedPayment] = React.useState<PaymentTransaction | null>(null);
  const [columns, setColumns] = React.useState<any[]>();
  const [showReverseTransactionDialog, setShowReverseTransactionDialog] = React.useState(false);
  const [showCaptureTransactionDialog, setShowCaptureTransactionDialog] = React.useState(false);
  const [showTransactionDetailDialog, setShowTransactionDetailDialog] = React.useState(false);
  const [showTransactionFilesDialog, setShowTransactionFilesDialog] = React.useState(false);
  const [reLoadTable, setReLoadTable] = React.useState(false);
  const [refundAmount, setRefundAmount] = React.useState<number>(0);
  const [partialRefundAvailable, setPartialRefundAvailable] = React.useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openSettings, setOpenSettings] = React.useState(false);
  const [focusedData, setFocusedData] = React.useState<any>([]);
  const date = new Date();
  const [selectionRange, setSelectionRange] = React.useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: new Date(date.getFullYear(), date.getMonth() + 1, 0),
    key: 'selection',
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSettings(true);
  };

  const getStatusChipColor = (status: any) => {
    switch (status) {
      case 0:
        return '#FF5733'; // PRE_AUTH_OR_WAITING_PAYMENT - Kırmızı
      case 1:
        return '#33FF57'; // COMPLETED - Yeşil
      case 2:
        return '#FFC300'; // TIMEOUT - Sarı
      case 3:
        return '#FF0000'; // FAILED - Kırmızı
      case 4:
        return '#DAF7A6'; // EMPTY - Açık Yeşil
      case 5:
        return '#33FFBD'; // CANCELLED_OR_RETURNED - Turkuaz
      case 6:
        return '#3385FF'; // WAITING_APPROVAL_CANCEL_OR_RETURN - Mavi
      case 7:
        return '#8A33FF'; // REJECTED_CANCEL_OR_RETURN - Mor
      case 8:
        return '#FF8D33'; // PARTIAL_RETURNED_OR_CANCELED - Turuncu
      case 20:
        return '#FFC300'; // PARTIAL_RETURNED_OR_CANCELED - Sarı
      case 30:
        return '#3385FF'; // PARTIAL_RETURNED_OR_CANCELED - Mavi
      default:
        return '#000000'; // Varsayılan renk - Siyah
    }
  };

  const actions: any[] = [
    (rowData: PaymentTransaction) => ({
      icon: (props: any) => (
        <Button
          variant="contained"
          style={{ background: 'orange' }}
          // eslint-disable-next-line react/destructuring-assignment
        >
          Seçenekler
        </Button>
      ),
      onClick: (event: any, rowData: any) => {
        setFocusedData(rowData);
        handleProfileMenuOpen(event);
      },
    }),
    /*  (rowData: PaymentTransaction) => ({
      icon: 'download',
      tooltip: 'Dosyaları İndir',
      disabled: !rowData.files || rowData.files.length === 0,
      onClick: (event: any, data: any) => {
        downloadFiles(data);
      },
    }),
    (rowData: PaymentTransaction) => ({
      icon: 'check',
      tooltip: 'Ödemeyi Onayla',
      disabled: rowData.status !== PaymentTransactionStatusEnum.PRE_AUTH_OR_WAITING_PAYMENT,
      onClick: (event: any, data: any) => {
        setSelectedPayment(data as PaymentTransaction);
        setShowCaptureTransactionDialog(true);
      },
    }),
    (rowData: PaymentTransaction) => ({
      icon: 'cancel',
      tooltip: 'Ödemeyi İptal / İade Et',
      disabled: rowData.isRefundable === false,
      onClick: (event: any, data: PaymentTransaction) => {
        setPartialRefundAvailable(
          data.transactionDate !== undefined &&
            new Date(data.transactionDate).toDateString() !== new Date().toDateString(),
        );
        setSelectedPayment(data);
        setRefundAmount(data.refundableAmount);
        setShowReverseTransactionDialog(true);
      },
    }),
    (rowData: PaymentTransaction) => ({
      icon: 'description',
      tooltip: 'Slip Bilgisini Gör',
      disabled: getDisabledStatusForTransactionSlip(rowData.status),
      onClick: (event: any, data: PaymentTransaction) => {
        setSelectedPayment(data);
        getPaymentTransactionSlip(data);
      },
    }), */
  ];

  const getColumns = (): any[] => {
    return [
      {
        title: 'Durum',

        render: (value: any) => (
          <Chip
            // eslint-disable-next-line react/destructuring-assignment
            label={getPaymentTransactionStatusEnumString(value.status as PaymentTransactionStatusEnum)}
            style={{ background: getStatusChipColor(value.status) }}
            disabled={
              Number(value.providerType) == Number(PaymentSourceEnum.NKOLAY) &&
              Number(value.status) == Number(PaymentTransactionStatusEnum.ACCOUNTING_CONTROL_WAITING) &&
              value.availableForCompleteTransaction != true
            }
          />
        ),
        field: 'status',
        type: 'string',
        cellStyle: { whiteSpace: 'nowrap' },
        sorting: true,
        lookup: getPaymentTransactionStatusEnumForLookup(),
        defaultFilter: statusFilter.map((x) => x.toString()),
        filterPlaceholder: 'Seçiniz',
        searching: false,
      },
      {
        title: 'Sağlayıcı',
        cellStyle: { whiteSpace: 'nowrap' },
        render: (value: any) => PaymentSourceEnumLabel.get(value.providerType),
        field: 'providerType',
        sorting: true,
        filtering: false,
        searching: false,
      },
      {
        title: 'İşlem No',
        cellStyle: { whiteSpace: 'nowrap' },
        field: 'transactionId',
        sorting: true,
        filtering: false,
        searching: false,
      },
      {
        title: 'Referans No',
        cellStyle: { whiteSpace: 'nowrap' },
        field: 'referenceNo',
        sorting: true,
        filtering: false,
        searching: false,
      },
      {
        title: 'Bayi Adı',
        cellStyle: { whiteSpace: 'nowrap' },
        field: 'user.agentName',
        sorting: true,
        searching: false,
        filtering: false,
      },
      {
        title: 'Bayi İl',
        cellStyle: { whiteSpace: 'nowrap' },
        field: 'user.cityName',
        sorting: true,
        searching: false,
        filtering: false,
      },
      {
        title: 'Müşteri Adı',
        field: 'cardHolder',
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Taksit Sayısı',
        field: 'instalment',
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Brüt Tutar',
        field: 'grossAmount',
        render: (value: any) => value.grossAmount?.toFixed(2),
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
      {
        title: 'Net Tutar',
        field: 'netAmount',
        render: (value: any) => value.netAmount?.toFixed(2),
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },

      {
        title: 'İşlem Tarihi',
        type: 'date',
        render: (value: any) => moment(value.transactionDate).format('DD/MM/YYYY HH:mm'),
        field: 'transactionDate',
        cellStyle: { whiteSpace: 'nowrap' },
        filtering: false,
        sorting: true,
        searching: false,
      },
    ];
  };

  const handleDialogClose = () => {
    setShowReverseTransactionDialog(false);
    setShowCaptureTransactionDialog(false);
    setShowTransactionDetailDialog(false);
    setShowTransactionFilesDialog(false);
  };

  const exportPaymentDetailAsPdf = async () => {
    if (!selectedPayment) {
      return;
    }

    const response = await Api.getPaymentDetailAsPdf(selectedPayment?.id);
    if (response) {
      downloadBlobAsFile(response, `odeme-detay${selectedPayment.id}.pdf`);
    } else {
      dispatch(
        showToast({
          message: `Bir sorun oluştu`,
          severity: 'warning',
        }),
      );
    }
  };

  const downloadFiles = async (paymentTransaction: any) => {
    if (!paymentTransaction.files || paymentTransaction.files.length == 0) {
      dispatch(
        showToast({
          message: `Bu işlem için hiç dosya yüklenmemiş.`,
          severity: 'warning',
        }),
      );
      return;
    }

    const urls = paymentTransaction.files.map((file: any) => file.url as string);
    for (const url of urls) {
      window.open(url, '_blank');
    }

    // const zipFileName = paymentTransaction.cardHolder.replaceAll(' ', '_');

    // const response = await downloadFilesFromUrlToZip(urls, zipFileName);
    // if (response && response.isSuccess) {
    //   dispatch(
    //     showToast({
    //       message: `Dosyalar başarıyla indirildi.`,
    //     }),
    //   );
    // } else {
    //   dispatch(
    //     showToast({
    //       message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
    //       severity: 'error',
    //     }),
    //   );
    // }
  };

  const captureTransaction = async () => {
    handleDialogClose();
    setShowLoading(true);
    if (selectedPayment) {
      const request = {
        paymentTransactionId: Number(selectedPayment.id),
        referenceNo: selectedPayment.referenceNo.toString(),
        transactionId: selectedPayment.transactionId?.toString(),
        xactId: selectedPayment.transactionIdHash?.toString(),
      } as PaynetCaptureTransactionRequest;

      const response = await Api.Paynet.captureTransaction(request, selectedPayment.providerType);
      if (response.isSuccess) {
        setReLoadTable(true);
        dispatch(
          showToast({
            message: `Ödeme onaylama işlemi başarılı!`,
          }),
        );
      } else {
        dispatch(
          showToast({
            message: `${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    }
    setShowCaptureTransactionDialog(false);
    setShowLoading(false);
    setSelectedPayment(null);
    setReLoadTable(false);
  };

  const onBlurRefundAmount = (event: any) => {
    const amount = event.target.value;
    setRefundAmount(Number(amount));
  };

  const validateRefundAmount = (): boolean => {
    if (!selectedPayment) {
      return false;
    }

    // kısmi iade yapılamıyorsa, true olarak dön. Çünkü tüm tutar iade olacak
    if (!partialRefundAvailable) {
      return true;
    }

    let message = null;
    if (refundAmount < 1) {
      message = `İade/iptal edeceğiniz tutar 1 TL'den büyük olmalıdır.`;
    }

    if (refundAmount > selectedPayment.refundableAmount) {
      message = `İade/iptal edebileceğiniz maksimum tutar: ${selectedPayment.refundableAmount} TL`;
    }

    if (selectedPayment.refundableAmount - refundAmount <= 1 && selectedPayment.refundableAmount - refundAmount !== 0) {
      message = `Lütfen daha yüksek iade/iptal tutarı giriniz.
                  (Girdiğiniz tutar ile birlikte kalan tutar 1 TL'den büyük olmalı)`;
    }

    if (message) {
      dispatch(
        showToast({
          message,
          severity: 'error',
        }),
      );
      return false;
    }

    return true;
  };

  const reverseTransaction = async () => {
    if (selectedPayment) {
      if (!validateRefundAmount()) {
        return;
      }

      handleDialogClose();
      setShowLoading(true);

      const request = {
        paymentTransactionId: Number(selectedPayment.id),
        xactId: selectedPayment.transactionIdHash?.toString(),
        amount: refundAmount,
      } as PaynetReverseTransactionRequest;

      const response = await Api.Paynet.reverseTransaction(request, selectedPayment.providerType);
      if (response.isSuccess) {
        setReLoadTable(true);
        dispatch(
          showToast({
            message: `İptal/İade talebi başarıyla oluşturuldu!`,
          }),
        );
      } else {
        dispatch(
          showToast({
            message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    }
    setShowReverseTransactionDialog(false);
    setShowLoading(false);
    setSelectedPayment(null);
    setReLoadTable(false);
  };

  const getPaymentTransactions = async (query: any) => {
    const { filters, orderBy, orderDirection, page, pageSize, search } = query;
    try {
      setShowLoading(true);
      const statusFilter = filters
        .find((x: Filter<any>) => x.column.field == 'status')
        ?.value.map((val: string) => Number(val) as PaymentTransactionStatusEnum);
      setStatusFilter(statusFilter);

      const requestDto = {
        paginationQuery: { page, pageSize } as PaginationQuery,
        providerType: PaymentSourceEnum.PAYNET,
        sortingQuery: getSortingQuery(orderBy, orderDirection),
        statuses: statusFilter,
        searchText: search,
        endDate: moment(selectionRange.endDate).local().toDate(),
        startDate: moment(selectionRange.startDate).local().toDate(),
      } as GetPaymentTransactionsRequestDto;

      const response = await Api.getPaymentTransactions(requestDto);
      if (response.isSuccess) {
        const { result } = response;
        return {
          data: result?.data ?? [],
          page,
          totalCount: result?.totalCount ?? 0,
        };
      }
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${e.message ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }

    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  };

  const getAllPaymentTransactions = async () => {
    setShowLoading(true);
    try {
      const requestDto = {
        providerType: PaymentSourceEnum.PAYNET,
        endDate: moment(selectionRange.endDate).local().toDate(),
        startDate: moment(selectionRange.startDate).local().toDate(),
      } as GetPaymentTransactionsRequestDto;

      const response = await Api.getTransactionsWithoutPagination(requestDto);
      if (response.isSuccess) {
        return response.result;
      }
      dispatch(
        showToast({
          message: `Ödeme listesi çekilirken bir hata oluştu. ${response.errorMessage}`,
          severity: 'error',
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Ödeme listesi çekilirken bir hata oluştu. ${e.message}`,
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }
    return null;
  };

  const getPaymentTransactionSlip = async (paymentTransaction: PaymentTransaction) => {
    if (paymentTransaction) {
      setShowLoading(true);

      const response = await Api.getPaymentTransactionSlip(paymentTransaction.id);
      if (response.isSuccess) {
        const newWindow = window.open(response.result?.url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
      } else {
        dispatch(
          showToast({
            message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
            severity: 'error',
          }),
        );
      }
    }
    setShowLoading(false);
    setSelectedPayment(null);
  };

  const sendToAccountingControlWaitingStatus = async (paymentTransaction: PaymentTransaction) => {
    if (!paymentTransaction) {
      return;
    }

    setShowLoading(true);

    const response = await Api.Paynet.sendToAccountingControlWaitingStatus(paymentTransaction.id);
    if (!response.isSuccess) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    } else {
      setReLoadTable(true);
    }

    setShowLoading(false);
    setSelectedPayment(null);
    setReLoadTable(false);
    setOpenSettings(false);
  };

  const sendToCompletedStatus = async (paymentTransaction: PaymentTransaction) => {
    if (!paymentTransaction) {
      return;
    }

    setShowLoading(true);

    const response = await Api.Paynet.sendToCompletedStatus(paymentTransaction.id);
    if (!response.isSuccess) {
      dispatch(
        showToast({
          message: `Bir hata oluştu. ${response.errorMessage ?? 'lütfen daha sonra tekrar deneyiniz!'}`,
          severity: 'error',
        }),
      );
    } else {
      setReLoadTable(true);
    }

    setShowLoading(false);
    setSelectedPayment(null);
    setReLoadTable(false);
    setOpenSettings(false);
  };

  const exportToExcelAllData = async (columns: any) => {
    const allPaymentTransactions = await getAllPaymentTransactions();
    if (!allPaymentTransactions) return;

    exportToExcel(columns, allPaymentTransactions);
  };

  const onDateRangeSelected = (event: any) => {
    setSelectionRange(event);

    setReLoadTable(true);
    setReLoadTable(false);
  };

  return (
    <React.Fragment>
      <Menu
        anchorEl={anchorEl}
        open={openSettings}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setOpenSettings(false)}
      >
        <MenuList>
          <MenuItem
            disabled={!focusedData.files || focusedData.files.length === 0}
            onClick={() => {
              setSelectedPayment(focusedData as PaymentTransaction);
              setShowTransactionFilesDialog(true);
            }}
          >
            <CloudDownload />
            <span style={{ marginLeft: 15 }}> Dosyaları Görüntüle</span>
          </MenuItem>
          <br />
          <MenuItem
            disabled={focusedData.status !== PaymentTransactionStatusEnum.PRE_AUTH_OR_WAITING_PAYMENT}
            onClick={() => {
              setSelectedPayment(focusedData as PaymentTransaction);
              setShowCaptureTransactionDialog(true);
            }}
          >
            <Check />
            <span style={{ marginLeft: 15 }}> Ödemeyi Onayla</span>
          </MenuItem>
          <br />
          <MenuItem
            disabled={focusedData.isRefundable === false}
            onClick={() => {
              setPartialRefundAvailable(
                focusedData.transactionDate !== undefined &&
                  new Date(focusedData.transactionDate).toDateString() !== new Date().toDateString(),
              );
              setSelectedPayment(focusedData);
              setRefundAmount(focusedData.refundableAmount);
              setShowReverseTransactionDialog(true);
            }}
          >
            <Cancel /> <span style={{ marginLeft: 15 }}>Ödemeyi İptal / İade Et</span>
          </MenuItem>
          <br />
          <MenuItem
            disabled={
              focusedData.providerType != PaymentSourceEnum.NKOLAY ||
              focusedData.status !== PaymentTransactionStatusEnum.WAITING_TO_SEND_ACCOUNTING_CONTROL
            }
            onClick={() => {
              setSelectedPayment(focusedData);
              sendToAccountingControlWaitingStatus(focusedData);
            }}
          >
            <Cancel /> <span style={{ marginLeft: 15 }}>Muhasebe Kontrolüne Gönder</span>
          </MenuItem>
          <br />
          <MenuItem
            disabled={
              focusedData.providerType != PaymentSourceEnum.NKOLAY ||
              focusedData.status !== PaymentTransactionStatusEnum.ACCOUNTING_CONTROL_WAITING ||
              focusedData.availableForCompleteTransaction != true
            }
            onClick={() => {
              setSelectedPayment(focusedData);
              sendToCompletedStatus(focusedData);
            }}
          >
            <Cancel /> <span style={{ marginLeft: 15 }}>Para Transferi Tamamlandı</span>
          </MenuItem>
          <br />
          <MenuItem
            disabled={focusedData.providerType != PaymentSourceEnum.NKOLAY}
            onClick={() => {
              setSelectedPayment(focusedData);
              setShowTransactionDetailDialog(true);
            }}
          >
            <Cancel /> <span style={{ marginLeft: 15 }}>Ödeme Detay</span>
          </MenuItem>
          <br />
          <MenuItem
            hidden={focusedData.providerType != PaymentSourceEnum.PAYNET}
            disabled={getDisabledStatusForTransactionSlip(focusedData.status)}
            onClick={() => {
              setSelectedPayment(focusedData);
              getPaymentTransactionSlip(focusedData);
            }}
          >
            <Description />
            <span style={{ marginLeft: 15 }}>Slip Bilgisini Gör</span>
          </MenuItem>
        </MenuList>
      </Menu>
      <br />
      <br />
      <IconButton onClick={() => history.goBack()}>
        <ArrowBack />
      </IconButton>
      <DateRangeSelectRow selectionRange={selectionRange} onSelectionRange={onDateRangeSelected} />
      {!reLoadTable && (
        <TranslatedMaterialTable
          title="Ödeme Listesi"
          columns={columns ?? getColumns()}
          data={getPaymentTransactions}
          actions={actions}
          isLoading={showLoading}
          options={{
            filtering: true,
            pageSize: 100,
            exportAllData: true,
            exportButton: { csv: true, pdf: false },
            exportCsv: exportToExcelAllData,
          }}
        />
      )}
      <ConfirmDialog
        title="Ödeme İptal/İade"
        open={showReverseTransactionDialog}
        // eslint-disable-next-line no-lone-blocks
        handleConfirm={reverseTransaction}
        handleClose={handleDialogClose}
      >
        {selectedPayment && partialRefundAvailable && (
          <div>
            <b>{selectedPayment?.transactionId}</b> numaralı işlem için iptal/iade etmek istediğiniz tutarı giriniz.{' '}
            <b>(Maks. {selectedPayment.refundableAmount} TL</b>)
            <br />
          </div>
        )}

        {selectedPayment && !partialRefundAvailable && (
          <div>
            <b>{selectedPayment?.transactionId}</b> numaralı işlemin tüm tutarı iade/iptal olacak. Ödemenin alındığı gün
            kısmi iade yapılamaz ve bütün tutar iade/iptal olur.
            <br />
          </div>
        )}

        {selectedPayment && (
          <TextField
            autoFocus
            margin="dense"
            id="refundableAmount"
            label="Tutar"
            type="number"
            disabled={!partialRefundAvailable}
            defaultValue={refundAmount}
            onBlur={onBlurRefundAmount}
          />
        )}
      </ConfirmDialog>
      <ConfirmDialog
        title="Ödemeyi Onayla"
        open={showCaptureTransactionDialog}
        // eslint-disable-next-line no-lone-blocks
        handleConfirm={captureTransaction}
        handleClose={handleDialogClose}
      >
        {selectedPayment && `${selectedPayment?.transactionId} numaralı işlemi onaylamak istediğinize emin misiniz?`}
      </ConfirmDialog>

      <ConfirmDialog
        title="Ödeme Detayı"
        confirmText="PDF Formatında İndir"
        cancelText="Kapat"
        open={showTransactionDetailDialog}
        // eslint-disable-next-line no-lone-blocks
        handleClose={handleDialogClose}
        handleConfirm={exportPaymentDetailAsPdf}
      >
        {selectedPayment && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6}>
                Satıcı İsmi (Ticari Ünvan)
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {selectedPayment.user.companyName ?? selectedPayment.user.agentName}
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                Satıcı Mail
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {selectedPayment.user.email}
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                Alıcı İsim
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {selectedPayment.cardHolder}
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                Aracın Plaka Bilgisi
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {selectedPayment.vehiclePlate}
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                Banka Bilgisi
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {selectedPayment.bankName}
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                Taksit Sayısı
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {selectedPayment.instalment}
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                Net Tutar
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {selectedPayment.netAmount} TL
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                Brüt Tutar
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {selectedPayment.grossAmount} TL
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                Satıcı Iban
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                {selectedPayment.user.iban}
              </Grid>
            </Grid>
          </Box>
        )}
      </ConfirmDialog>

      <ConfirmDialog
        title="Yüklenen Dosyalar"
        confirmText=""
        cancelText="Kapat"
        open={showTransactionFilesDialog}
        // eslint-disable-next-line no-lone-blocks
        handleClose={handleDialogClose}
        handleConfirm={handleDialogClose}
      >
        {selectedPayment && (
          <Box>
            <Grid container spacing={2}>
              {selectedPayment.files
                .filter((x) => x.url.endsWith('pdf'))
                .map((item) => (
                  <Grid key={`img-${item.id}`} item xs={6} sm={6} md={6}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img src="/pdf1.png" alt="" style={{ width: '%100', height: 200 }} />
                    </a>
{/* 
                    <div style={{ width: '50%' }}>
                      <p>
                        <a href={item.url} target="_blank" rel="noreferrer">
                          Görüntüle
                        </a>
                      </p>
                      <a href={item.url} target="_blank" rel="noreferrer">
                        <img src="/pdf.png" alt="" style={{ width: '%100', height: 200 }} />
                      </a>
                      <object
                        data={item.url}
                        type="application/pdf"
                        width="80%"
                        height="100%"
                        style={{ marginTop: '100' }}
                      />
                    </div> */}
                  </Grid>
                ))}
              {selectedPayment.files
                .filter((x) => !x.url.endsWith('pdf'))
                .map((item) => (
                  <Grid key={`img-${item.id}`} item xs={6} sm={6} md={6}>
                    <a href={item.url} target="_blank" rel="noreferrer">
                      <img src={item.url} alt="" style={{ width: '%100', height: 250 }} />
                    </a>
                  </Grid>
                ))}
            </Grid>
          </Box>
        )}
      </ConfirmDialog>
    </React.Fragment>
  );
};

export default PaymentListPage;
